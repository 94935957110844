import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { notify } from "../actions/CommonActions";

export const RejectMandateModal = ({
  showReject,
  toggleReject,
  updateMandates,
  isUpdating,
  setRejectionReason,
  rejectionReason,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={showReject}
      onHide={toggleReject}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Reject Mandate</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to reject this mandate?</p>
        <Form.Group>
          <Form.Label>Reason for rejecting this mandate</Form.Label>
          <Form.Control
            // value={note}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "red",
            fontSize: "0.8rem",
            fontWeight: 500,
            border: 0,
          }}
          onClick={() => {
            if (rejectionReason === "") {
              notify("warning", "Notice", "Reason is compulsory");
            } else {
              updateMandates("rejected");
            }
          }}
          disabled={isUpdating}
        >
          {isUpdating ? "Please wait.." : "Reject"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
