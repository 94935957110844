import React from "react";

export const ErrorPage = () => {
  return (
    <div>
      <h1>OOPS</h1>
      <p>404 Not Found</p>
    </div>
  );
};
