import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { batchSuspendMandate, downloadSuspendFormat } from "../../services";
import { notify } from "../actions/CommonActions";
import { AuthContext } from "../../helpers/AuthContext";

export const BatchSuspendModal = ({ show, toggle, refetch }) => {
  const [batchFile, setBatchFile] = useState([]);
  const [isSuspending, setIsSuspending] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSuspend = () => {
    setIsSuspending(true);
    batchSuspendMandate(batchFile, user)
      .then((res) => {
        if (res.status === "success") {
          const storedPage = sessionStorage.getItem("currentPage");
          const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
          notify("success", "success", res.message);
          setIsSuspending(false);
          toggle();
          refetch(initialPage);
        } else {
          notify("danger", "OOPS", "something went wrong");
          setIsSuspending(false);
        }
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsSuspending(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Define the expected headers
        const expectedHeaders = ["subscriberCode", "resumption", "reason"]; // Replace with your expected headers

        // Get the headers from the first row of the Excel file
        const headersInFile = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        })[0];

        // Check if the headers in the file match the expected headers
        const isValidFile = expectedHeaders.every((header) =>
          headersInFile.includes(header)
        );

        if (!isValidFile) {
          notify(
            "danger",
            "Invalid file",
            "Invalid file structure. Please upload a file with the correct headers."
          );
          return;
        }

        // const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Assuming the first row contains headers/names for the properties
        const headers = headersInFile;

        // Create an array of objects using headers as property names
        // const dataArray = excelData.slice(1).map((row) => {
        //   const obj = {};
        //   headers.forEach((header, index) => {
        //     obj[header] = row[index];
        //   });
        //   return obj;
        // });

        const dataArray = XLSX.utils
          .sheet_to_json(worksheet, { header: 1 })
          .slice(1)
          .map((row) => {
            const obj = {};
            row.forEach((cell, index) => {
              // Check if the cell is a date and convert it to JS Date
              if (headers[index] && worksheet[`${headers[index]}_t`] === "n") {
                const dateCode = cell;
                const jsDate = XLSX.SSF.parse_date_code(dateCode);
                obj[headers[index]] = jsDate;
              } else {
                obj[headers[index]] = cell;
              }
            });
            return obj;
          });

        setBatchFile(dataArray);
        dataArray?.length > 0 ? setIsUploaded(true) : setIsUploaded(false);
      };

      reader.readAsBinaryString(file);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Batch Suspend Mandate</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/file/Assets/SuspendTemplate.xlsx`}
          style={{ fontSize: "0.8rem" }}
        >
          Download excel format (Date must be in the{" "}
          <b>
            <code>YYYY-MM-DD</code>
          </b>{" "}
          format)
        </a>
        <Form>
          <Form.Label>upload file</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form>

        {batchFile?.length > 0 && (
          <>
            <code className="mt-4">
              The following {batchFile?.length} mandates would be suspended
            </code>
            <Table>
              <thead>
                <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                  <th width="11%">Mandate Code</th>
                  <th width="1%">Resumption Date</th>
                  <th width="1%">Reason</th>
                </tr>
              </thead>
              {batchFile?.length < 1 ? (
                <tr>
                  <td colSpan={"5"}>No mandate found...</td>
                </tr>
              ) : (
                <tbody>
                  {batchFile?.map((mandate) => {
                    return (
                      <tr
                        className="tr"
                        onClick={() => {
                          navigate(`/mandate-details`, {
                            state: { mandate: mandate },
                          });
                        }}
                      >
                        <td className="gray-td">{mandate?.subscriberCode}</td>
                        <td className="gray-td">{mandate?.resumption}</td>
                        <td className="gray-td">{mandate?.reason}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {batchFile?.length > 0 && (
          <Button
            style={{
              //   backgroundColor: "red",
              fontSize: "0.8rem",
              fontWeight: 500,
              border: 0,
            }}
            onClick={() => {
              handleSuspend();
            }}
            disabled={isSuspending}
          >
            {isSuspending ? "Suspending, please wait..." : "Suspend"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
