import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../helpers/AuthContext";

export const ChangePasswordModal = ({
  show,
  toggle,
  handleChangePassword,
  isChanging,
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <div> */}
        <Modal.Title>Change Password</Modal.Title>
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to proceed? Proceeding will send a new password
          to the user.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={(e) => {
            e.preventDefault();
            handleChangePassword();
          }}
          disabled={isChanging}
        >
          {isChanging ? "Changing..." : "Change"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
