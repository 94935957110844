import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";

export const DeleteMandateModal = ({
  show,
  toggle,
  handleDeleteMandate,
  isDeleting,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      centered
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <div> */}
        <Modal.Title>Delete Mandate</Modal.Title>
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this mandate?</p>
        <code style={{ color: "red" }}>Note: this cannot be undone</code>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={handleDeleteMandate}
        >
          {isDeleting ? "Please wait..." : "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
