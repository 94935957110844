import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../helpers/AuthContext";

export const ChangeRoleModal = ({
  show,
  toggle,
  setRoleId,
  handleChangeRole,
  isChanging,
}) => {
  const { roles } = useContext(AuthContext);

  const role = roles?.data;
  const navigate = useNavigate();
  return (
    <Modal
      centered
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <div> */}
        <Modal.Title>Change Role</Modal.Title>
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Select new role</Form.Label>
          <Form.Select
            // value={mandate.accountName}
            type="text"
            name="accountName"
            onChange={(e) => setRoleId(e.target.value)}
          >
            <option>-Select-</option>
            {role?.map((r) => {
              return <option value={r?.id}>{r.name}</option>;
            })}
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={(e) => {
            e.preventDefault();
            handleChangeRole();
          }}
          disabled={isChanging}
        >
          {isChanging ? "Changing..." : "Change"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
