import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorPage } from "./pages/ErrorPage";
import { Root } from "./pages/Root";
import { CreateMandate } from "./pages/CreateMandate";
import { AllMandates } from "./pages/AllMandates";
import { Products } from "./pages/Products";
import { Reports } from "./pages/Reports";
import { MandateDetails } from "./pages/MandateDetails";
import { Login } from "./pages/Login";
import { ReactNotifications } from "react-notifications-component";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryCLient = new QueryClient();

root.render(
  <QueryClientProvider client={queryCLient}>
    <React.StrictMode>
      <ReactNotifications />
      <App />
    </React.StrictMode>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
