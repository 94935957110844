import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ApproveMandateModal = ({
  showApprove,
  toggleApprove,
  updateMandates,
  isUpdating,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={showApprove}
      onHide={toggleApprove}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Approve Mandate</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to approve this mandate?</p>
        <code style={{ color: "#000" }}>
          NB: Approving will send the mandate to Nibss
        </code>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          disabled={isUpdating}
          onClick={() => {
            updateMandates("approved");
          }}
        >
          {isUpdating ? "Please wait..." : "Approve"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
