import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FiDownload, FiFilter } from "react-icons/fi";
import { GoDotFill, GoFilter } from "react-icons/go";
import { FilterModal } from "../components/modals/FilterModal";
import { useNavigate } from "react-router";
import { getAllMandates, getMyMandates, getTransactions } from "../services";
import { AuthContext } from "../helpers/AuthContext";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { notify } from "../components/actions/CommonActions";

export const Transactions = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [mandates, setMandates] = useState();
  const [isFetchingMandates, setIsFetchingMandates] = useState(false);
  const { user } = useContext(AuthContext);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  function Items({ currentItems }) {
    return (
      <>
        <Table hover responsive className="mt-4">
          <thead>
            <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
              {/* <th width="1%">S/N</th> */}
              {/* <th width="11%">Session ID</th> */}
              {/* <th width="11%">Work Flow Code</th> */}
              {/* <th width="11%">Last Action By</th> */}
              <th>Transaction ID</th>
              <th>Beneficiary Details</th>
              <th>Originator Details</th>
              <th>Amount</th>
              <th>narration</th>
              {/* <th width="11%">Reference</th> */}
              {/* <th width="11%">End Date</th> */}
              {/* <th width="11%">Next Debit Date</th> */}
              <th>Date Added</th>
              <th>Status</th>
              {/* <th>Status</th> */}
              {/* <th>Payer Name</th> */}
            </tr>
          </thead>
          {isFetching ? (
            <tr>
              <td className="gray-td" colSpan={"5"}>
                Loading, please wait...
              </td>
            </tr>
          ) : mandates?.data?.length < 1 ? (
            <tr>
              <td colSpan={"5"}>No mandate found...</td>
            </tr>
          ) : (
            <tbody>
              {currentItems?.map((mandate) => {
                return (
                  <tr
                    className="tr"
                    // onClick={() => {
                    //   navigate(`/mandate-details`, {
                    //     state: { mandate: mandate },
                    //   });
                    // }}
                  >
                    <td className="gray-td">{mandate?.transactionId}</td>
                    <td className="gray-td">
                      <span className="text-body font-weight-bold cursor-pointer">
                        {mandate?.beneficiaryAccountName}
                      </span>
                      <br />
                      <span className="text-muted">
                        {mandate?.beneficiaryAccountNumber}
                      </span>
                    </td>
                    <td className="gray-td">
                      <span className="text-body font-weight-bold cursor-pointer">
                        {mandate?.originatorAccountName}
                      </span>
                      <br />
                      <span className="text-muted">
                        {mandate?.originatorAccountNumber}
                      </span>
                    </td>
                    <td className="gray-td">{mandate?.amount}</td>
                    <td className="gray-td">{mandate?.narration}</td>
                    <td className="gray-td">
                      {new Date(mandate?.createdAt).toLocaleDateString()}
                    </td>
                    <td>
                      <span
                        style={{
                          color: `${
                            mandate?.status === "success" ? "green" : "red"
                          }`,
                          fontSize: "0.8rem",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <GoDotFill style={{ fontSize: "0.6rem" }} />{" "}
                        {mandate?.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </>
    );
  }

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 8;

  const currentItems = mandates?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(mandates?.length / 8);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 8) % mandates?.length;

    setItemOffset(newOffset);
  };

  const { data, status, refetch, isSuccess, isLoading, isFetching } = useQuery(
    "transactions",
    getTransactions,
    {
      onSuccess: (res) => {
        setMandates(res?.response);
      },
      onError: (error) => {
        notify("danger", "Error", error.response.data.message);
        console.log(error);
      },
    }
  );

  // useEffect(() => {
  //   fetchMandates();
  // }, []);

  const navigate = useNavigate();
  return (
    <>
      {/* {isLoading ? (
        <>loading, please wait...</>
      ) : ( */}
      <>
        {/* <div className="mt-4 d-flex align-items-center justify-content-between">
          <button className="light-button" onClick={toggleFilter}>
            {" "}
            <GoFilter /> Filter
          </button>
          <button className="light-button">
            {" "}
            <FiDownload /> Download Excel
          </button>
        </div> */}

        <Items currentItems={currentItems} />
        <div className="pagination-container">
          <ReactPaginate
            activeClassName="active-page"
            // breakClassName="item break-me "
            breakLabel="..."
            containerClassName={"pagination"}
            disabledClassName={"disabled-page"}
            marginPagesDisplayed={2}
            nextClassName={"item next "}
            nextLabel={
              // <ArrowForwardIosIcon style={{ fontSize: 18, width: 150 }} />
              ">"
            }
            onPageChange={handlePageClick}
            pageCount={pageCount}
            pageClassName={"item pagination-page "}
            pageRangeDisplayed={5}
            renderOnZeroPageCount={null}
            previousClassName={"item previous"}
            previousLabel={
              // <ArrowBackIosIcon style={{ fontSize: 18, width: 150 }} />
              "<"
            }
          />
        </div>
        <FilterModal toggleFilter={toggleFilter} showFilter={showFilter} />
      </>
      {/* // )} */}
    </>
  );
};
