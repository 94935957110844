import React, { useContext, useState } from "react";
import { NewUserModal } from "../components/modals/NewUserModal";
import { IoPerson } from "react-icons/io5";
import { Col, Dropdown, DropdownDivider, Row } from "react-bootstrap";
import {
  activateUser,
  changeRole,
  deactivateUser,
  forgotPassword,
  getUsers,
} from "../services";
import { useQuery } from "react-query";
import { notify } from "../components/actions/CommonActions";
import { ChangeRoleModal } from "../components/modals/ChangeRoleModal";
import { AuthContext } from "../helpers/AuthContext";
import { ChangePasswordModal } from "../components/modals/ChangePasswordModal";
import { DeactivateUserModal } from "../components/modals/DeactivateUserModal";
import { ActivateUserModal } from "../components/modals/ActivateUserModal";

export const AdminPanel = () => {
  const [showUser, setShowUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [showChange, setShowChange] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDeactivateUser, setShowDeactivateUser] = useState(false);
  const [showActivateUser, setShowActivateUser] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isChanging, setIsChanging] = useState(false);
  const [email, setEmail] = useState("");
  const { user } = useContext(AuthContext);

  const toggleShowUser = () => {
    setShowUser(!showUser);
  };

  const toggleShowPassword = () => {
    setShowChangePassword(!showChangePassword);
  };

  const toggleShowChange = () => {
    setShowChange(!showChange);
  };

  const toggleShowDeactivateUser = () => {
    setShowDeactivateUser(!showDeactivateUser);
  };

  const toggleShowActivateUser = () => {
    setShowActivateUser(!showActivateUser);
  };

  const { data, status, refetch, isSuccess, isLoading, isFetching } = useQuery(
    "all-users",
    getUsers,
    {
      onSuccess: (res) => {
        setUsers(res?.response);
      },
      onError: (error) => {
        notify("danger", "Error", "Something went wrong");
        console.log(error);
      },
    }
  );

  const roles = sessionStorage.getItem("roles");
  const parsedRoles = JSON.parse(roles);

  const getRole = (roleId) => {
    const role = parsedRoles?.data?.find((role) => role.id === roleId);

    return role?.name;
  };

  const handleChangeRole = async () => {
    setIsChanging(true);
    try {
      await changeRole(roleId, userId, user).then((res) => {
        if (res.status === "success") {
          notify("success", "success", res.message);
          setShowChange(false);
          setIsChanging(false);
          refetch();
        } else {
          setIsChanging(false);
          notify("danger", "OOPs", "something went wrong");
        }
      });
    } catch (error) {
      console.log(error);
      setIsChanging(false);
      notify("danger", "OOPs", "something went wrong");
    }
  };

  const handleChangePassword = async () => {
    setIsChanging(true);
    try {
      forgotPassword(email)
        .then((res) => {
          notify("success", "success", res.message);
          setShowChangePassword(false);
          setIsChanging(false);
        })
        .catch((error) => {
          notify("danger", "OOPS", error.response.data.message);
          console.log(error);
          setIsChanging(false);
        });
    } catch (error) {
      console.log(error);
      setIsChanging(false);
      notify("danger", "OOPs", "something went wrong");
    }
  };

  const handleDeactivateUser = async () => {
    setIsChanging(true);
    try {
      await deactivateUser(userId, user).then((res) => {
        if (res.status === "success") {
          notify("success", "success", res.message);
          setShowDeactivateUser(false);
          setIsChanging(false);
          refetch();
        } else {
          setIsChanging(false);
          notify("danger", "OOPs", "something went wrong");
        }
      });
    } catch (error) {
      console.log(error);
      setIsChanging(false);
      notify("danger", "OOPs", "something went wrong");
    }
  };

  const handleActivateUser = async () => {
    setIsChanging(true);
    try {
      await activateUser(userId, user).then((res) => {
        if (res.status === "success") {
          notify("success", "success", res.message);
          setShowActivateUser(false);
          setIsChanging(false);
          refetch();
        } else {
          setIsChanging(false);
          notify("danger", "OOPs", "something went wrong");
        }
      });
    } catch (error) {
      console.log(error);
      setIsChanging(false);
      notify("danger", "OOPs", "something went wrong");
    }
  };

  return (
    <>
      <Row className="mt-3">
        {isFetching ? (
          <>Getting users...</>
        ) : (
          <Col>
            <div className="w-100">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: "15px",
                }}
              >
                <h6>USERS</h6>
                <button
                  onClick={() => {
                    toggleShowUser();
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "0.5px solid gray",
                    fontSize: "0.9rem",
                    borderRadius: "5px",
                  }}
                  className="p-1 d-flex align-items-center gap-2"
                >
                  <IoPerson /> +
                </button>
              </div>
              <table className="w-100">
                <thead>
                  <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>...</th>
                  </tr>
                </thead>

                <tbody>
                  {users?.map((user) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "0.5px solid #f1f1f1",
                        }}
                      >
                        <td>
                          {user?.firstName} {user?.lastName}
                        </td>
                        <td>{user?.email}</td>
                        <td>{getRole(user?.roleId)}</td>
                        <td
                          style={{
                            color: user?.status === 1 ? "green" : "red",
                          }}
                        >
                          {user?.status === 1 ? "active" : "inactive"}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              style={{
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                backgroundColor: "transparent",
                                color: "#000",
                                border: "none",
                                padding: 0,
                              }}
                              id="dropdown-basic"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
                              <Dropdown.Item
                                onClick={() => {
                                  toggleShowChange();
                                  setUserId(user?.id);
                                }}
                              >
                                change role
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  toggleShowPassword();
                                  setEmail(user?.email);
                                }}
                              >
                                update password
                              </Dropdown.Item>
                              {user?.status === 1 && (
                                <Dropdown.Item
                                  onClick={() => {
                                    toggleShowDeactivateUser();
                                    setUserId(user?.id);
                                  }}
                                >
                                  Deactivate user
                                </Dropdown.Item>
                              )}

                              {user?.status === 2 && (
                                <Dropdown.Item
                                  onClick={() => {
                                    toggleShowActivateUser();
                                    setUserId(user?.id);
                                  }}
                                >
                                  Activate user
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        )}
        {/* <Col></Col> */}
      </Row>
      <NewUserModal
        showUser={showUser}
        setShowUser={setShowUser}
        toggleShowUser={toggleShowUser}
        refetch={refetch}
      />
      <ChangeRoleModal
        show={showChange}
        toggle={toggleShowChange}
        setRoleId={setRoleId}
        isChanging={isChanging}
        handleChangeRole={handleChangeRole}
      />
      <ChangePasswordModal
        show={showChangePassword}
        toggle={toggleShowPassword}
        isChanging={isChanging}
        handleChangePassword={handleChangePassword}
      />

      <DeactivateUserModal
        show={showDeactivateUser}
        toggle={toggleShowDeactivateUser}
        isChanging={isChanging}
        handleDeactivateUser={handleDeactivateUser}
      />

      <ActivateUserModal
        show={showActivateUser}
        toggle={toggleShowActivateUser}
        isChanging={isChanging}
        handleActivateUser={handleActivateUser}
      />
    </>
  );
};
