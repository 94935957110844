import React, { useContext, useState } from "react";
import circles from "../assets/images/Circles.png";
import loginImg from "../assets/images/logining.png";
import logo from "../assets/images/blueLogo.png";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  changePassword,
  forgotPassword,
  loginUser,
  registerNewUser,
  validateUser,
} from "../services";
import { useNavigate } from "react-router-dom";
import { notify } from "../components/actions/CommonActions";
import { AuthContext } from "../helpers/AuthContext";

export const Login = () => {
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isSubmittingOtp, setIsSubmittingOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setUser, loading, setLoading, roles, setIsAuthenticated } =
    useContext(AuthContext);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [otpSent, setOtpSent] = useState({
    token: "",
  });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isLoggingin, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    setIsLoading(true);
    forgotPassword(login.email)
      .then((res) => {
        notify("success", "success", res.message);
        setIsForgotPassword(false);
        setIsLoading(false);
      })
      .catch((error) => {
        notify("danger", "OOPS", error.response.data.message);
        console.log(error);
        setIsLoading(false);
      });
  };

  const handlePasswordChange = async () => {
    if (password === confirmPassword) {
      setIsPasswordChanging(true);
      await changePassword({ email: login?.email, password: password })
        .then((res) => {
          console.log(res);
          setIsPasswordChanging(false);
          if (res?.status === "success") {
            notify("success", "Password changed", res?.message);
            setIsPasswordChange(false);
          }
        })
        .catch((error) => {
          console.log(error);
          notify("danger", "OOPS", error?.response?.data?.message);
          setIsPasswordChanging(false);
        });
      // console.log("yay");
    } else {
      notify("danger", "OOPS", "Password do not match");
    }
  };

  const handleLoginChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLogin({ ...login, [name]: value });
  };

  const handleOtpChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOtpSent({ ...otpSent, [name]: value });
  };

  const loginAcc = () => {
    // e.preventDefault();
    setIsLoggingIn(true);
    loginUser(login?.email, login?.password)
      .then((res) => {
        console.log("res ", res);
        if (res?.code === "200AUTH") {
          // console.log("works");
          notify("success", "OTP Sent", res?.response?.message);
          setIsOtp(true);
        }
        if (res?.code === "002") {
          notify("success", "Change password", res?.message);
          setIsPasswordChange(true);
          sessionStorage.setItem("token", res?.token);
          setIsLoggingIn(false);
        }
        if (res?.auth) {
          setIsAuthenticated(true);
          sessionStorage.setItem("token", res?.token);
          setUser(res);
          sessionStorage.setItem("userdata", JSON.stringify(res));
          navigate("/all-mandates");
          setIsLoggingIn(false);
        }
      })
      .catch((error) => {
        setIsLoggingIn(false);
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log(error);
      });
  };

  const submitOtp = () => {
    // e.preventDefault();
    setIsSubmittingOtp(true);
    validateUser({ ...otpSent, email: login?.email })
      .then((res) => {
        console.log("res ", res);
        if (res?.code === "002") {
          notify("success", "Change password", res?.message);
          setIsPasswordChange(true);
          // sessionStorage.setItem("userdata", res?.token);
          setIsSubmittingOtp(false);
        }
        if (res?.auth) {
          setIsAuthenticated(true);
          sessionStorage.setItem("token", res?.token);
          setUser(res);
          sessionStorage.setItem("userdata", JSON.stringify(res));
          navigate("/all-mandates");
          setIsLoggingIn(false);
        }
      })
      .catch((error) => {
        setIsSubmittingOtp(false);
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log(error);
      });
  };

  return (
    <Row style={{ height: "100vh" }}>
      <Col
        xs={6}
        style={{
          backgroundColor: "#007dcc",
          position: "relative",
          color: "#fff",
        }}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
        <h4>Central Mandate Management System</h4>
        <img
          src={loginImg}
          style={{
            width: "48%",
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          alt="login"
        />
        <img src={circles} style={{ width: "80%" }} alt="circles" />
      </Col>

      {!isForgotPassword &&
        (!isPasswordChange ? (
          <Col
            xs={6}
            className="d-flex align-items-center gap-5 justify-content-center flex-column"
          >
            <div style={{ width: "65%", marginBottom: "150px" }}>
              <img
                src={logo}
                alt="logo"
                style={{ width: "150px", marginBottom: "120px" }}
              />
              {!isOtp ? (
                <>
                  <h3>Login</h3>
                  <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
                    Enter your credentials
                  </p>
                  <Form className="d-flex flex-column gap-3">
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control name="email" onChange={handleLoginChange} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleLoginChange}
                      />
                    </Form.Group>
                    {/* <p
                  style={{
                    cursor: "pointer",
                    fontSize: "0.8rem",
                    textDecoration: "underline",
                    color: "#007dcc",
                  }}
                  onClick={() => {
                    setIsForgotPassword(true);
                  }}
                >
                  Forgot password
                </p> */}

                    <Button
                      onClick={() => {
                        //   e.preventDefault();
                        loginAcc();
                      }}
                      className="w-100"
                      style={{ backgroundColor: "#007dcc" }}
                      disabled={isLoggingin}
                    >
                      {isLoggingin ? "Logging in..." : "Login"}
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <h3>Enter OTP</h3>
                  <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
                    Enter the your Otp below.
                  </p>
                  <Form className="d-flex flex-column gap-3">
                    <Form.Group>
                      <Form.Label>Enter OTP</Form.Label>
                      <Form.Control
                        name="token"
                        value={otpSent?.token}
                        onChange={handleOtpChange}
                      />
                    </Form.Group>
                    <Button
                      onClick={() => {
                        //   e.preventDefault();
                        // console.log("otp sent ",otpSent);
                        submitOtp();
                      }}
                      className="w-100"
                      style={{ backgroundColor: "#007dcc" }}
                      disabled={isSubmittingOtp}
                    >
                      {isSubmittingOtp ? "Validating..." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        //   e.preventDefault();
                        // console.log(isOtp);
                        setIsOtp(false);
                        setIsLoggingIn(false);
                      }}
                      className="w-100"
                      style={{ backgroundColor: "red" }}
                      // disabled={isSubmittingOtp}
                    >
                      Back to Login
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </Col>
        ) : (
          <Col
            xs={6}
            className="d-flex align-items-center gap-5 justify-content-center flex-column"
          >
            <div style={{ width: "65%", marginBottom: "150px" }}>
              <img
                src={logo}
                alt="logo"
                style={{ width: "150px", marginBottom: "120px" }}
              />
              <h3>Change Password</h3>
              <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
                You need to change your password for your first login
              </p>
              <Form className="d-flex flex-column gap-3">
                <Form.Group>
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Form.Label>
                    Password must be 8 characters, 1 uppercase, 1 lowercase, 1
                    number, 1 symbol
                  </Form.Label>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirm-password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>

                <Button
                  onClick={() => {
                    //   e.preventDefault();
                    handlePasswordChange();
                  }}
                  className="w-100"
                  style={{ backgroundColor: "#007dcc" }}
                  disabled={isPasswordChanging}
                >
                  {isPasswordChanging ? "Changing, Please wait..." : "Change"}
                </Button>
              </Form>
            </div>
          </Col>
        ))}
      {isForgotPassword && (
        <Col
          xs={6}
          className="d-flex align-items-center gap-5 justify-content-center flex-column"
        >
          <div style={{ width: "65%", marginBottom: "150px" }}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "150px", marginBottom: "120px" }}
            />
            <h3>Forgot password</h3>
            <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
              Enter your email address. A new password would be sent to you.
            </p>
            <Form className="d-flex flex-column gap-3">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={login.email}
                  name="email"
                  onChange={handleLoginChange}
                />
              </Form.Group>
              {/* <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={handleLoginChange}
                  />
                </Form.Group> */}
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "0.8rem",
                  textDecoration: "underline",
                  color: "#007dcc",
                }}
                onClick={() => {
                  setIsForgotPassword(false);
                }}
              >
                Back to login
              </p>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  // loginAcc();
                  handleForgotPassword();
                }}
                className="w-100"
                style={{ backgroundColor: "#007dcc" }}
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Send"}
              </Button>
            </Form>
          </div>
        </Col>
      )}
    </Row>
  );
};
