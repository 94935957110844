import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";

export const FoundMandates = ({ show, toggle, mandates }) => {
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={toggle} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Mandates Found</Modal.Title>
          <span style={{ fontSize: "0.8rem", color: "#475467" }}>
            Select a mandate to view full info
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex w-100 align-items-start flex-column gap-3">
          {mandates?.response?.map((mandate) => {
            return (
              <div
                style={{
                  border: "0.5px solid #007dcc",
                  padding: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/mandate-details?code=${mandate?.mandateCode}`, {
                    state: { mandate: mandate },
                  });
                  toggle();
                }}
              >
                <span className="text-muted">{mandate?.accountName}</span>
                <p
                  className="m-0"
                  style={{ fontSize: "0.9rem", fontWeight: 500 }}
                >
                  {mandate?.narration}
                </p>
                <p
                  className="m-0"
                  style={{ fontSize: "0.9rem", fontWeight: 500 }}
                >
                  {mandate?.mandateCode}
                </p>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
        >
          Search
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
