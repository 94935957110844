import { useState, useEffect } from "react";

const useActivityMonitor = (refreshTokenCallback, interval = 3000000) => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let activityTimer;

    const handleUserActivity = () => {
      setIsActive(true); // Set user as active
      clearTimeout(activityTimer); // Clear previous timer
      activityTimer = setTimeout(() => {
        setIsActive(false); // Set user as inactive after specified interval
        refreshTokenCallback(); // Callback function to refresh token
      }, interval);
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsActive(false); // Set user as inactive when tab is hidden
        clearTimeout(activityTimer); // Clear timer when tab is hidden
      } else {
        handleUserActivity(); // Resume activity monitoring when tab becomes visible
      }
    };

    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    // document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup function
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      // document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(activityTimer);
    };
  }, [refreshTokenCallback, interval]);

  return isActive;
};

export default useActivityMonitor;
