import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notify } from "../actions/CommonActions";

export const SuspendMandateModal = ({
  show,
  toggle,
  handleSuspendMandate,
  isSuspending,
}) => {
  const [resumption, setIsResumption] = useState(0);
  const [note, setNote] = useState("");
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={toggle} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Suspend Mandate</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to suspend this mandate?</p>
        <Form>
          <Form.Group>
            <Form.Label>Resumption Date</Form.Label>
            <Form.Control
              type="date"
              value={resumption}
              onChange={(e) => setIsResumption(e.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Reason for suspending this mandate</Form.Label>
            <Form.Control
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            // backgroundColor: "red",
            fontSize: "0.8rem",
            fontWeight: 500,
            border: 0,
          }}
          onClick={() => {
            if (note === "") {
              notify("warning", "Notice", "Reason is compulsory");
            } else {
              handleSuspendMandate(resumption, note);
            }
          }}
          disabled={isSuspending}
        >
          {isSuspending ? "Suspending, please wait.." : "Suspend"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
