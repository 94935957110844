import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";

export const UpdateMandateModal = ({
  toggleUpdate,
  showUpdate,
  handleUpdateMandateFormChange,
  mandateForm,
  updateMandate,
  setMandateForm,
  isUpdatingMandate,
}) => {
  return (
    <Modal
      show={showUpdate}
      onHide={toggleUpdate}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Update mandate</Modal.Title>
          <span style={{ fontSize: "0.8rem", color: "#475467" }}>
            Make changes to the mandate here
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Payer Name</Form.Label>
              <Form.Control
                name="payerName"
                onChange={handleUpdateMandateFormChange}
                defaultValue={mandateForm.payerName}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="phoneNumber"
                onChange={handleUpdateMandateFormChange}
                defaultValue={mandateForm.phoneNumber}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                name="emailAddress"
                onChange={handleUpdateMandateFormChange}
                defaultValue={mandateForm.emailAddress}
              />
            </Form.Group>
          </Row>
          <Form.Group as={Col} className="mb-3" controlId="formGridPassword">
            <Form.Label>Payer Address</Form.Label>
            <Form.Control
              name="payerAddress"
              onChange={handleUpdateMandateFormChange}
              defaultValue={mandateForm.payerAddress}
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Status</Form.Label>
              <Form.Control
                name="status"
                onChange={handleUpdateMandateFormChange}
                defaultValue={mandateForm.status}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Work Flow Status</Form.Label>
              <Form.Control
                name="workFlowStatus"
                onChange={handleUpdateMandateFormChange}
                defaultValue={mandateForm.workFlowStatus}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={() => {
            updateMandate();
          }}
          disabled={isUpdatingMandate}
        >
          {isUpdatingMandate ? "Updating mandate..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
